import Axios from '../../axios/axios';


export const sendMail = async (contactData) => {
    let mailData = {
        to: "yashprataps01@gmail.com",
        subject: "!!!!!PORTFOLIO CONTACT!!!!!",
        text: `SENDER NAME: ${contactData.name} EMAIL: ${contactData.email} : MSG: ${contactData.message}`
    }
    try {
        return await Axios.post('/mail/sendMail', mailData);
    } catch(e){
        console.log("error");
    }
}