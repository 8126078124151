import React, { Component } from "react";

import classes from "./Skills.module.css";
import DeveloperIcon from "../../Assets/icons/developer.svg";
import ChatbotIcon from "../../Assets/icons/chatbot.png";
import ProgrammerIcon from "../../Assets/icons/programmer.svg";

class Skills extends Component {
  render() {
    return (
      <div className={classes["Skills"]}>
        <div className={classes["skill"] + " " + classes["skill--border"]}>
          <div className={classes["skill__icon"]}>
            <img src={DeveloperIcon} alt="dev icon" />
          </div>
          <h4 className={classes["skill__title"]}>Full Stack Developer</h4>
          <p className={classes["skill__description"]}>
            I love bringing ideas to life via code and my magic recipe includes
            React.js, Node.js, MongoDB and Express.js
          </p>
          {/* <h5 className={classes["skill__subtitle1"]}>
            Languages I speak
          </h5>
          <p className={classes["skill__linear-list"]}>
            Javascript, Python, C++, C
          </p> */}
          <h5 className={classes["skill__subtitle2"]}>Tech Stack 👨🏻‍💻</h5>
          <ul className={classes["skill__list"]}>
            <li>React.js</li>
            <li>Node.js</li>
            <li>Express</li>
            <li>MongoDB</li>
            <li>ElasticSearch</li>
            <li>RabbitMQ</li>
            <li>Docker</li>
            <li>AWS</li>
          </ul>
        </div>
        <div className={classes["skill"] + " " + classes["skill--border"]}>
          <div className={classes["skill__icon--bot"]}>
            <img height={60} width={60} src={ChatbotIcon} alt="dev icon" />
          </div>
          <h4 className={classes["skill__title"]}>Chatbot Developer</h4>
          <p className={classes["skill__description"]}>
            I build AI powered complete chatbot solutions for increasing user
            engagement and creating 'wow' moments
          </p>
          <h5 className={classes["skill__subtitle2"]}>Tech & Tools 🛠️</h5>
          <ul className={classes["skill__list"]}>
            <li>Botkit</li>
            <li>MS Botframework</li>
            <li>Azure Bot Service</li>
            <li>Google's Dialogflow</li>
            <li>NLP/NLU</li>
            <li>Entity extraction</li>
            <li>FB/Slack Devtools</li>
            <li>Sentiment Analysis</li>
          </ul>
        </div>
        <div class={classes["skill"]}>
          <div className={classes["skill__icon"]}>
            <img src={ProgrammerIcon} alt="dev icon" />
          </div>
          <h4 className={classes["skill__title"]}>Mentor</h4>
          <p className={classes["skill__description"]}>
            I believe teaching is the best way to learn, and genuinely enjoy
            mentoring new developers and blogging
          </p>
          <h5 className={classes["skill__subtitle2"]}>
            Mentoring experiences 👨‍🏫
          </h5>
          <ul className={classes["skill__list"]}>
            <li>
              Guide junior developers and interns in my team and workplace
            </li>
            <li>Publish educational blogs</li>
            <li>Conducted lectures on Competitive Programming</li>
            <li>Coordinated Programming clubs</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Skills;
