import React, { Component } from "react";

import classes from "./RecentWorks.module.css";
import ImagexThumbnail from "../../Assets/images/imagex-thumb.png";
import PaaysThumbnail from "../../Assets/images/paays-thumb.png";
import VizitorThumbnail from "../../Assets/images/vizitor-thumb.png";
import RevoThumbnail from "../../Assets/images/Revo.png";
import PaulCamperThumbnail from "../../Assets/images/PaulCamper.png";
import MosaicThumbnail from "../../Assets/images/Mosaic.png";

class RecentWorks extends Component {
  render() {
    return (
      <div className={classes["Recent-works"]}>
        <h2 className={classes["title"]}>My Recent Work</h2>
        <h4 className={classes["subtitle"]}>
          Here are some of the projects I've worked on. Want to see more?
          <a
            className={classes["mail-link"]}
            href="mailto:yashprataps01@gmail.com"
          >
            {" "}
            Email me.
          </a>
        </h4>
        <div className={classes["projects"]}>
          <div className={classes["projects__project"]}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://paulcamper.de/"
            >
              <img src={PaulCamperThumbnail} alt="project 1" />
            </a>
          </div>
          <div className={classes["projects__project"]}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://host.drawbotics.com/revo/demos/river-place/index.html"
            >
              <img src={RevoThumbnail} alt="project 1" />
            </a>
          </div>
          <div className={classes["projects__project"]}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://imagex.yashpratapsingh.com"
            >
              <img src={ImagexThumbnail} alt="project 1" />
            </a>
          </div>
          <div className={classes["projects__project"]}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://app.trymosaic.com/"
            >
              <img src={MosaicThumbnail} alt="project 1" />
            </a>
          </div>
          <div className={classes["projects__project"]}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.vizitorapp.com/"
            >
              <img src={VizitorThumbnail} alt="project 2" />
            </a>
          </div>
          <div className={classes["projects__project"]}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.paays.com/"
            >
              <img src={PaaysThumbnail} alt="project 3" />
            </a>
          </div>
        </div>
        <div className={classes["projects__chatbot"]}>
          <h4 className={classes["chatbot__title"]}>Chatbots and NLP</h4>
          <iframe
            title="startiq-chatbot"
            className={classes["chatbot__yt"]}
            src="https://www.youtube.com/embed/XDxAgnQCx5A"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    );
  }
}

export default RecentWorks;
