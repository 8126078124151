import React from 'react';
import { PulseLoader } from 'react-spinners';

import classes from './Button.module.css';


const Button = ({loading, textColor, title}) => {
    let btnClasses = [classes["btn"]];
    if(textColor === "white"){
        btnClasses.push(classes["btn--white"]);
    }
    if(textColor === "primary"){
        btnClasses.push(classes["btn--primary"]);
    }
    if(loading) {
        btnClasses.push(classes["disabled"])
    }
    return (
        <button disabled={loading} className={btnClasses.join(' ')}>{loading ? <PulseLoader color="#0984e3" /> : title}</button>
    )
}

export default Button;