import React, { Component } from "react";
import { Link } from "react-router-dom";

import Logo from "../../Assets/images/yash.png";
import classes from "./Header.module.css";

class Header extends Component {
  render() {
    return (
      <div className={classes["Header"]}>
        <div className={classes["logo"]}>
          <Link to="/">
            <img className={classes["logo__img"]} src={Logo} alt="logo" />
          </Link>
        </div>
        <div className={classes["mentorship"]}>
          <p className={classes["mentorship__text"]}>
            <a href="https://medium.com/@singhyashpratap06" target="__blank">
              Blogs
            </a>
          </p>
        </div>
        <div className={classes["hello"]}>
          <Link to="/contact">Say Hi</Link>
        </div>
      </div>
    );
  }
}

export default Header;
