import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import classes from "./Footer.module.css";
import Logo from "../../Assets/images/yash.png";
import Button from "../UI/Button/Button";

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <div className={classes["start-project"]}>
          <div className={classes["start-project__title"]}>
            <h2>Start a Project</h2>
          </div>
          <div className={classes["start-project__description"]}>
            <p>
              Interested in working together? We should queue up a chat. I’ll
              buy the coffee.
            </p>
          </div>
          <div className={classes["start-project__btn"]}>
            <Link to="/contact">
              <Button textColor="white" title="Let's do this &nbsp;🚀" />
            </Link>
          </div>
        </div>
        <div className={classes["footer"]}>
          <div className={classes["logo"]}>
            <Link to="/">
              <img className={classes["logo__img"]} src={Logo} alt="logo" />
            </Link>
          </div>
          <p className={classes["short-description"]}>
            Living, learning, & leveling up one day at a time.
          </p>
          <p className={classes["social-links"]}>
            <a
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/yashpratapsingh/"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
            <a href="https://angel.co/u/yash-pratap-singh-5" target="_blank">
              <i class="fab fa-angellist"></i>
            </a>
            <a
              href="mailto:yashprataps01@gmail.com"
              rel="noopener noreferrer"
              target="_blank"
              className={classes["social-links_link"]}
            >
              <i class="far fa-envelope"></i>
            </a>
            <a href="https://medium.com/@singhyashpratap06" target="_blank">
              <i class="fab fa-medium"></i>
            </a>
            <a
              style={{ marginRight: 0 }}
              rel="noopener noreferrer"
              href="https://github.com/convexhull"
              target="_blank"
            >
              <i class="fab fa-github-square"></i>
            </a>
          </p>
          <p className={classes["buildtool-info"]}>
            Made with <i class="fas fa-heart"></i> using ReactJS
          </p>
        </div>
      </Fragment>
    );
  }
}

export default Footer;
