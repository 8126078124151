import React, { Component, Fragment } from "react";

import classes from "./Home.module.css";
import Header from "../../Components/Header/Header";
import HeroSection from "../../Components/Hero/Hero";
import PersonalIntro from "../../Components/PersonalIntro/PersonalIntro";
import Skills from "../../Components/Skills/Skills";
import RecentWorks from "../../Components/RecentWorks/RecentWorks";
// import Collab from "../../Components/Collab/Collab";
// import Projects from "../../Components/Projects/Projects";
// import Testimonials from "../../Components/Testimonial/Testimonial";
import Footer from "../../Components/Footer/Footer";

class Home extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <section className={classes["hero-section"]}>
          <HeroSection />
        </section>
        <section className={classes["personal-intro-section"]}>
          <PersonalIntro />
        </section>
        <section className={classes["skills-section"]}>
          <Skills />
        </section>
        <section className={classes["recent-works-section"]}>
          <RecentWorks />
        </section>
        {/* <section className={classes["collab-section"]}>
          <Collab />
        </section> */}
        {/* <section className={classes["projects-section"]}>
          <Projects />
        </section> */}
        {/* <section className={classes["testimonials-section"]}>
          <Testimonials />
        </section> */}
        <section className={classes["footer"]}>
          <Footer />
        </section>
      </Fragment>
    );
  }
}

export default Home;
