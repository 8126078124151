import React, { Component } from "react";

import classes from "./Hero.module.css";
import Avatar from "../../Assets/images/yps-avatar.svg";
import Illustration from "../../Assets/images/illustration.svg";

class Hero extends Component {
  render() {
    return (
      <div className={classes["Hero"]}>
        <h1 className={classes["title"]}>Full Stack Engineer</h1>
        <p className={classes["subtitle"]}>
          Crafting Seamless User Experiences, One Line at a Time!
        </p>
        <div className={classes["profile-pic"]}>
          <img src={Avatar} alt="avatar" />
        </div>
        <div className={classes["illustration"]}>
          <img src={Illustration} alt="tech stack illustration" />
        </div>
      </div>
    );
  }
}

export default Hero;
