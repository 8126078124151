import React, { Component } from "react";

import classes from "./PersonalIntro.module.css";

class PersonalIntro extends Component {
  render() {
    return (
      <div className={classes["Personal-intro"]}>
        <h2 className={classes["title"]}>Hi, I’m Yash. Nice to meet you.</h2>
        <p className={classes["description"]}>
          Senior Full Stack Engineer with deep expertise in React, Node,
          MongoDB, Express, and related tech stack. I have 5+ years of
          experience working remotely in fast-paced settings with distributed
          cross-functional teams.
          <br />
          Working for multiple startups and clients, I have the experience of
          successfully planning, architecting and launching projects from
          scratch
        </p>
      </div>
    );
  }
}

export default PersonalIntro;
