import React, { Component } from "react";

import Header from "../../Components/Header/Header";
import classes from "./Contact.module.css";
import Avatar from "../../Assets/images/yps-avatar.svg";
import Button from "../../Components/UI/Button/Button";

import { sendMail } from "../../api/contact/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Contact extends Component {
    state = {
        form: {
            name: "",
            email: "",
            message: "",
            submitted: false,
        },
        loading: false
    };

    updateFormHandler = (event, element) => {
        let updatedValue = event.target.value;
        this.setState((state, props) => {
            let form = {
                ...state.form,
                [element]: updatedValue,
            };
            return {
                form,
            };
        });
    };

    formSubmitHandler = async (event) => {
        event.preventDefault();
        try {
            this.setState({
                loading: true
            })
            await sendMail(this.state.form);
            toast(`Hi ${this.state.form.name}! I will contact you on your email as soon as possible. Have a great day ahead ;)`);
        } catch (e) {
            toast.error(`Hi ${this.state.form.name}. Some error has occurred. I'll look into this. Please try again after some time.`);
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    render() {
        return (
            <div className={classes["contact"]}>
                <ToastContainer />
                <Header />
                <div className={classes["avatar"]}>
                    <img src={Avatar} alt="yash's avatar" />
                </div>
                <h1 className={classes["title"]}>
                    Thanks for taking the time to reach out. How can I help you
                    today?
                </h1>
                <form
                    className={classes["contact-form"]}
                    onSubmit={this.formSubmitHandler}
                >
                    <div className={classes["contact-form__elements"]}>
                        <div className={classes["contact-form__name"]}>
                            <label>Name</label>
                            <input
                                type="text"
                                value={this.state.form.name || ""}
                                onChange={(event) =>
                                    this.updateFormHandler(event, "name")
                                }
                            />
                        </div>
                        <div className={classes["contact-form__email"]}>
                            <label>Email</label>
                            <input
                                type="email"
                                value={this.state.form.email}
                                onChange={(event) =>
                                    this.updateFormHandler(event, "email")
                                }
                            />
                        </div>
                        <div className={classes["contact-form__message"]}>
                            <label>Message</label>
                            <textarea
                                onChange={(event) =>
                                    this.updateFormHandler(event, "message")
                                }
                            />
                        </div>
                    </div>
                    <div
                        className={classes["submit-btn"]}
                        onClick={this.formSubmitHandler}
                    >
                        <Button title="Submit" loading={this.state.loading}/>
                    </div>
                </form>
            </div>
        );
    }
}

export default Contact;